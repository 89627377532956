body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-slide .ql-editor img {
  display: inline;
}

.responsive-iframe {
  position: absolute;
  top: 30;
  left: 00;
  bottom: 30;
  right: 0;
  margin-left: 10;
  padding-left: 10;
  width: 100%;
  height: 80%;
  border: none;
}



.Bordered .ant-modal-content {
  border-radius: 10px;
  /* background: red; */
}
